import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { ButtonComponent } from './button/button.component';
import { TextInputComponent } from './text-input/text-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './card/card.component';
import { SliderComponent } from './slider/slider.component';
import { IconSvgComponent } from './icon-svg/icon-svg.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TabComponent } from './tab/tab.component';
import { ModalComponent } from './modal/modal.component';
import { ToastPopupComponent } from './toast-popup/toast-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/auth/auth.api';
import { DownIconComponent } from '../../@icons/down-icon.component';
import { LoadingIconComponent } from "../../@icons/loading-icon.component";
import { SkeletonLoaderComponent } from '../@components/loaders/skeleton-loader/skeleton-loader.component';

@NgModule({
  declarations: [
    ButtonComponent,
    TextInputComponent,
    CardComponent,
    SliderComponent,
    IconSvgComponent,
    DropdownComponent,
    CardComponent,
    ModalComponent,
    ToastPopupComponent,
    TabComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSliderModule,
    ReactiveFormsModule,
    TranslateModule,
    DownIconComponent,
    LoadingIconComponent,
    SkeletonLoaderComponent
  ],
  exports: [
    ButtonComponent,
    TextInputComponent,
    SliderComponent,
    IconSvgComponent,
    DropdownComponent,
    CardComponent,
    ModalComponent,
    ToastPopupComponent,
    TabComponent
  ]
})
export class SharedModule { }
