<div class="relative">
  <!-- Prefix Icon -->
  @if (prefix) {
  <ng-container>
    <span class="absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
      <ng-container *ngTemplateOutlet="prefix"></ng-container>
    </span>
  </ng-container>
  }


  <!-- Input Field -->
  <ng-container *ngIf="type === 'radio' || type === 'checkbox'; else textInput">
    <input [type]="type" [checked]="checked" (change)="onCheckboxChange($event)" [disabled]="disabled"
      class="w-6 h-6 text-primary cursor-pointer " />
  </ng-container>

  <ng-template #textInput>
    <input [type]="type" [placeholder]="placeholder | translate" [(ngModel)]="value" (input)="onInput($event)"
      [disabled]="disabled" class="w-full h-12 shadow p-3 text-base text-black rounded-xl outline-none" [ngClass]="{
           'pl-12': prefix,
        'border-red-500': isError,
        'border-gray-300': !isError && !disabled,
        'bg-gray-100': disabled
      }" />
  </ng-template>
  <!-- Suffix Icon -->
  @if (suffix) {
  <ng-container>
    <span class="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
      <ng-container *ngTemplateOutlet="suffix"></ng-container>
    </span>
  </ng-container>
  }
  <span *ngIf="type === 'password' || showingPassword" (click)="togglePassword($event)"
    class="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
    <vw-icon-svg iconUrl="/assets/svg/eye-off.svg" additionalClasses="w-5 h-5"></vw-icon-svg>
  </span>

</div>