export class QueryParamService {
  buildListQueryParams(page, limit, sortField, sortOrder) {
    let queryParamString = '';
    queryParamString = this.concatKeyValue(queryParamString, 'page', page ? page.toString() : '0');
    queryParamString = this.concatKeyValue(queryParamString, 'limit', limit ? limit.toString() : '');
    queryParamString = this.concatKeyValue(queryParamString, 'sortField', sortField ? sortField.toString() : '');
    queryParamString = this.concatKeyValue(queryParamString, 'sortOrder', sortOrder ? sortOrder.toString() : '');
    return queryParamString;
  }
  /**
   * @param params map of query param
   * @param queryString base query string
   * build query params with null values exlcude
   */
  addExtraQueryParams(params, queryString) {
    if (params && params.size > 0) {
      params.forEach((value, key) => {
        queryString = this.concatKeyValue(queryString, key, value);
      });
    }
    return queryString;
  }
  /**
   * @param queryString base query string
   * @param key key of the property (name)
   * @param value value of the porperty
   * contact param into query string => ?key=value if value is not null
   */
  concatKeyValue(queryString, key, value) {
    if (key && value && value.length > 0) {
      if (queryString.indexOf('?') >= 0) {
        queryString += '&';
      } else {
        queryString += '?';
      }
      return queryString += encodeURIComponent(key) + '=' + encodeURIComponent(value);
    } else {
      return queryString;
    }
  }
}