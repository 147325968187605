var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { SdkConfigService } from '../../config/sdk-config.service';
import { BaseApi } from '../@common/base-api';
export class UserApi extends BaseApi {
  constructor() {
    super('users');
  }
  update(item, lang) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.patch('', item);
    });
  }
  addUserAddressUid(uid) {
    this.storageService.setItem(SdkConfigService.getInstance().getConfig().addressName, uid);
  }
  getUserAddressUid() {
    return this.storageService.getItem(SdkConfigService.getInstance().getConfig().addressName);
  }
  getCurrentUser() {
    return __awaiter(this, void 0, void 0, function* () {
      return this.get('');
    });
  }
  list(request, page = 1, limit = 50) {
    return __awaiter(this, void 0, void 0, function* () {
      const queryParams = this.queryParamService.buildListQueryParams(page, limit);
      return this.post(`list${queryParams}`, request);
    });
  }
  registerGuest(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('register-guest', request);
    });
  }
  register(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('register', request);
    });
  }
  confirm(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('confirm', request);
    });
  }
  reSendConfirm(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('resend-confirm', request);
    });
  }
  //change-password , reset-password, confirm-password
  changePassword(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('change-password', request);
    });
  }
  resetPassword(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('reset-password', request);
    });
  }
  confirmPassword(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('confirm-password', request);
    });
  }
}