import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * DropdownComponent represents a customizable dropdown menu.
 * It allows users to select an option from a list of provided choices.
 */
@Component({
  selector: 'vw-dropdown', // Selector for the dropdown component
  templateUrl: './dropdown.component.html', // Path to the template for the dropdown
  styleUrls: ['./dropdown.component.scss'], // Path to the styles for the dropdown
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor {
  @Input() options: any[] = []; // Array of options to be displayed in the dropdown
  @Input() placeholder: string = 'Select an option'; // Placeholder text when no option is selected
  @Input() backgroundStyle: 'white' | 'primary' | 'outline-primary' | 'outline' = 'white'
  @Input() textStyle: 'black' | 'white' | 'primary' = 'primary'
  selectedValue: any | null = null; // Currently selected value from the dropdown
  isOpen = false; // Tracks whether the dropdown is currently open or closed

  // Callbacks for the ControlValueAccessor
  onChange: (value: any | null) => void = () => {};
  onTouched: () => void = () => {};

  // Injecting ElementRef to gain access to the dropdown's DOM element
  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  writeValue(value: any | null): void {
    this.selectedValue = value;
    this.cdr.detectChanges(); // Forcer la mise à jour
  }

  /**
   * Toggles the dropdown's open/closed state.
   * If the dropdown is currently closed, it will be opened; if open, it will be closed.
   */
  toggleDropdown() {
    this.isOpen = !this.isOpen; // Toggle the dropdown state
  }

  /**
   * Selects an option from the dropdown and closes it.
   * Emits the selected option to the parent component.
   * @param option - The option that was selected.
   */
  selectOption(option: any) {
    this.selectedValue = option; // Update the selected value
    this.onChange(this.selectedValue); // Emit the new value to the form
    this.writeValue(this.selectedValue); // Update the view
    this.onTouched(); // Mark as touched
    this.isOpen = false; // Close the dropdown after selection
  }


  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn; // Register the change callback
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn; // Register the touched callback
  }

  /**
   * Closes the dropdown when a click is detected outside of it.
   * Listens for click events on the entire document.
   * @param event - The click event that triggered this method.
   */
  @HostListener('document:click', ['$event']) // Listen to clicks on the document
  onDocumentClick(event: MouseEvent) {
    // Check if the click occurred outside the dropdown component
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isOpen = false; // Close the dropdown if the click was outside
    }
  }
}
