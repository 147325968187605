import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'vw-button', // Selector for the button component
  templateUrl: './button.component.html', // Template file for the button component
  styleUrls: ['./button.component.scss'] // Corrected to 'styleUrls' for consistency with Angular documentation
})
export class ButtonComponent {
  @Input() label: string = 'Button'; // Button label text
  @Input() type: 'button' | 'submit' | 'reset' = 'button'; // Button type attribute
  @Input() color: string = 'bg-primary hover:bg-white'; // Background color classes for the button
  @Input() borderColor: string = 'border-primary'; // Border color classes for the button
  @Input() textColor: string = 'text-white'; // Text color classes for the button
  @Input() icon: string = ''; // Optional icon class or name to be displayed on the button
  @Input() iconPosition: 'left' | 'right' = 'left'; // Position of the icon relative to the text
  @Input() customClass: string = ''; // Any additional custom classes to apply to the button
  @Input() iconCustomClass: string = ''; // Any additional custom classes to apply to the button
  @Output() onClick = new EventEmitter<void>(); // Event emitter for click events
  @Input() look: 'primary' | 'secondary' | 'clear' | 'danger' = 'primary'; // Button look style
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() iconTemplate: TemplateRef<any> | null;


  /**
   * Handles the button click event and emits the onClick event.
   */
  handleClick() {
    if (!this.disabled) {
      this.onClick.emit(); // Emit the click event to the parent component
    }
  }


  getButtonClasses(): { [key: string]: boolean } {
    return {
      [this.borderColor]: !!this.borderColor,
      [this.customClass]: !!this.customClass,
      'bg-gray-200': this.disabled,
      'bg-primary': this.look === 'primary' && !this.disabled,
      'text-white': (this.look === 'primary' || this.look === 'secondary' || this.look === 'danger') || this.disabled,
      'text-primary': this.look === 'clear' && !this.disabled,
      'border-primary': (this.look === 'clear' || this.look === 'primary') && !this.disabled,
      'bg-secondary': this.look === 'secondary' && !this.disabled,
      'bg-red-500': this.look === 'danger' && !this.disabled,
      'border-red-500': this.look === 'danger' && !this.disabled,
      'hover:bg-primary-700': this.look === 'primary' && !this.disabled,
      'hover:bg-red-700': this.look === 'danger' && !this.disabled,
      'hover:bg-secondary-700': this.look === 'secondary' && !this.disabled,
      'active:bg-primary-800': this.look === 'primary' && !this.disabled,
      'active:bg-red-800': this.look === 'danger' && !this.disabled,
      'active:bg-secondary-800': this.look === 'secondary' && !this.disabled
    };
  }


}
