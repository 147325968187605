import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  @Input() tabs: Array<{
    label: string,
    labelPayload?: any,
    icon?: string,
    tailwindClasses?: string,
    height?: string,
    width?: string,
    border?: string
  }> = []; // Array of tabs with customizable properties
  @Input() isLoading: boolean = false;

  @Input() activeTab: number = 0; // Keep track of the active tab

  @Output() tabSelected = new EventEmitter<number>(); // Emit event on tab click

  selectTab(index: number) {
    this.activeTab = index;
    this.tabSelected.emit(index); // Emit event when tab is selected
  }
}
