var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { WebViewAction } from "./enums/webview-action";
import { Subject } from "rxjs";
export class FlutterStorageHelper {
  static getInstance() {
    if (!this.instance) {
      this.instance = new FlutterStorageHelper();
    }
    return this.instance;
  }
  constructor() {
    this.getValueSubjects = {};
  }
  getValue(key) {
    return __awaiter(this, void 0, void 0, function* () {
      return new Promise((resolve, reject) => {
        if (!this.getValueSubjects[key]) {
          this.getValueSubjects[key] = new Subject();
        }
        this.getValueSubjects[key].subscribe(res => {
          this.getValueSubjects[key].unsubscribe(); //TODO maybe manage multiple subscriptions ?
          resolve(res);
        });
        this.sendToFlutter(WebViewAction.readValue, {
          key
        });
      });
    });
  }
  writeValue(key, value) {
    this.sendToFlutter(WebViewAction.storeValue, {
      key,
      value
    });
  }
  deleteValue(key, value) {}
  listenMessage() {
    this.handleMessageCallback = this.handleMessageFromFlutter.bind(this);
    window.addEventListener('message', this.handleMessageCallback, false);
  }
  /**
   * @param event postMessage event
   * @private
   * @description Handle message from any other postMessage candidate (Mobile App, Webview, etc)
   */
  handleMessageFromFlutter(event) {
    if (event === null || event === void 0 ? void 0 : event.data) {
      const message = event.data instanceof String ? JSON.parse(event.data) : event.data;
      switch (message.type) {
        case WebViewAction.readValue:
          if (message.payload && message.payload.key && this.getValueSubjects[message.payload.key]) {
            this.getValueSubjects[message.payload.key].next(message.payload);
          } else {
            console.error('No subject for message');
          }
        default:
          console.warn('Unknown message type', message.type);
      }
    }
  }
  /**
   *
   * @param message Message to be sent at the other postMessage candidate (Mobile App, Webview, etc)
   * @description Send message to any other postMessage candidate (Mobile App, Webview, etc)
   */
  sendToFlutter(type, payload) {
    if (window.mobileAppMessageHandler) {
      window.mobileAppMessageHandler.postMessage(JSON.stringify({
        type,
        payload
      }));
    }
  }
  ngOnDestroy() {
    if (this.handleMessageCallback) {
      window.removeEventListener('message', this.handleMessageCallback, false);
    }
  }
}