// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  LANG: 'LANG',
  HOST_BASE: 'https://api-dev.vegiwise.com',
  API_BASE: 'https://api-dev.vegiwise.com/api/v1',
  JWT_EXPIRE_OFFSET: 10,
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  USERNAME: 'USERNAME',
  GUEST_UID: 'GUEST_UID',
  GUEST_EMAIL: 'GUEST_EMAIL',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  MAP_TILER_KEY: 'EZHAIDNRDJumoWlbexiY',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
