var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { Observable } from 'rxjs';
import { SdkConfigService } from '../../config/sdk-config.service';
import { User } from '../../generated/@entities/app/user.entity';
import { BaseApi } from '../@common/base-api';
import { JwtHelper } from '../@common/helpers/jwt.helper';
export class AuthApi extends BaseApi {
  constructor() {
    super('oauth');
    this.urlRedirection = null;
    this.deepUrl = null;
    this.jwtHelperService = JwtHelper.getInstance();
  }
  /**
   * delete access_token & refresh_token from localStorage
   */
  logout() {
    this.deleteToken();
    this.deleteRefreshToken();
  }
  /**
   * @param username email of the user
   * @param password password of the user
   * login user and set access_token & refresh_token in localStorage
   * @returns Oauth2Response with access_token & refresh_token
   */
  login(username, password) {
    return __awaiter(this, void 0, void 0, function* () {
      const res = yield this.post(`token`, {
        username,
        password,
        grant_type: 'password'
      });
      if (res && res.access_token && res.refresh_token) {
        this.addToken(res.access_token);
        this.addRefreshToken(res.refresh_token);
      }
      return res;
    });
  }
  loginAsGuest(username) {
    return __awaiter(this, void 0, void 0, function* () {
      let req = {
        grant_type: 'guest',
        username,
        password: ''
      };
      const response = yield this.post(`token`, req);
      if (response && response.access_token && response.refresh_token) {
        this.addToken(response.access_token);
        this.addRefreshToken(response.refresh_token);
      }
      return response;
    });
  }
  /**
   * @param url to keep
   * @param queryParams to keep
   */
  memorizeURL(url, queryParams) {
    this.urlRedirection = url;
    this.deepUrl = {
      queryParams
    };
  }
  /**
   * reset this.urlRedirection & this.deepUrl
   */
  resetMemorizeURL() {
    this.urlRedirection = null;
    this.deepUrl = null;
  }
  /**
   * check if user is logged
   * 1) check if access_token present & still valid in localStorage
   * 2.a) If access_token ok => return true
   * 2.b) If access_token not valid => check if refresh_token present & still valid in localStorage
   * 2.b.a) If valid send refresh request to back & process response => return true if req ok or false if not
   * 2.b.b) If not valid return false
   */
  isAuthenticate() {
    return new Observable(observer => {
      const accessToken = this.getToken();
      const refreshToken = this.getRefreshToken();
      // No accessToken && refresh in LocalStorage => not authenticate...
      if (!accessToken && !refreshToken) {
        observer.next(false);
        observer.complete();
      }
      // AccessToken expired => try to refresh...
      if (!accessToken || this.isTokenExpired(accessToken)) {
        // RefreshToken exist and valid => call refreshToken...
        if (refreshToken && (!this.jwtHelperService.isJwt(refreshToken) ||
        // not JWT => can't check expiration => try anyway
        !this.isTokenExpired(refreshToken))) {
          this.refreshToken(refreshToken).then(res => {
            observer.next(!!res);
            observer.complete();
          }).catch(() => {
            observer.next(false);
            observer.complete();
          });
        } else {
          observer.next(false);
          observer.complete();
        }
      } else {
        // AccessToken still valid...
        observer.next(true);
        observer.complete();
      }
      return observer;
    });
  }
  getCurrentUser() {
    let userDto = new User();
    userDto.uid = this.getSub();
    userDto.email = this.getEmail();
    userDto.firstName = this.getFirstName();
    userDto.lastName = this.getLastName();
    return userDto;
  }
  /**
   * decode access_token from localStorage
   */
  decodeToken() {
    return this.jwtHelperService.decodeToken(this.getToken());
  }
  /**
   * get expiration date of the access_token from localStorage
   */
  expirationDate() {
    return this.jwtHelperService.getTokenExpirationDate(this.getToken());
  }
  /**
   * check if access_token is expired
   * return true if expired, false if not
   */
  isExpired() {
    var _a;
    return this.jwtHelperService.isTokenExpired(this.getToken(), (_a = SdkConfigService.getInstance().getConfig().JWT_EXPIRE_OFFSET) !== null && _a !== void 0 ? _a : 10);
  }
  /**
   * @param token token JWT
   * check if token is expired
   * return true if expired, false if not
   */
  isTokenExpired(token) {
    var _a;
    return this.jwtHelperService.isTokenExpired(token, (_a = SdkConfigService.getInstance().getConfig().JWT_EXPIRE_OFFSET) !== null && _a !== void 0 ? _a : 10);
  }
  /**
   * get the userID
   */
  getSub() {
    return this.decodeToken() ? this.decodeToken().sub : null;
  }
  /**
   * get the if of the notary office targeted by the notary connected
   */
  getFirstName() {
    const payload = this.decodeToken();
    return payload && payload.firstName ? payload.firstName : null;
  }
  /**
   * get the if of the notary office targeted by the notary connected
   */
  getLastName() {
    const payload = this.decodeToken();
    return payload && payload.lastName ? payload.lastName : null;
  }
  /**
   * get the if of the notary office targeted by the notary connected
   */
  getEmail() {
    const payload = this.decodeToken();
    return payload && payload.email ? payload.email : null;
  }
  /**
   * get access_token from localStorage
   */
  getUserName() {
    return this.getEmail();
  }
  /**
   * add access_token in localStorage
   */
  addToken(value, requiredRole) {
    const token = this.jwtHelperService.decodeToken(value);
    // Check if user has one of the required role to log in
    const hasRole = () => !requiredRole || requiredRole.length === 0 || token.roles.some(role => requiredRole.includes(role));
    if (token && hasRole()) {
      const config = SdkConfigService.getInstance().getConfig();
      this.storageService.setItem(config.accessTokenName, value);
      if (config.storeOnMobile) {
        this.storageService.setMobileStorageItem(config.accessTokenName, value);
      }
    } else {
      throw new Error('Only admin can log in this app');
    }
  }
  /**
   * get access_token from localStorage
   */
  getToken() {
    return this.storageService.getItem(SdkConfigService.getInstance().getConfig().accessTokenName);
  }
  /**
   * delete access_token in localStorage
   */
  deleteToken() {
    this.storageService.removeItem(SdkConfigService.getInstance().getConfig().accessTokenName);
  }
  /**
   * add refresh_token in localStorage
   */
  addRefreshToken(value) {
    const config = SdkConfigService.getInstance().getConfig();
    this.storageService.setItem(config.refreshTokenName, value);
    if (config.storeOnMobile) {
      this.storageService.setMobileStorageItem(config.refreshTokenName, value);
    }
  }
  /**
   * get refresh_token from localStorage
   */
  getRefreshToken() {
    return this.storageService.getItem(SdkConfigService.getInstance().getConfig().refreshTokenName);
  }
  /**
   * delete refresh_token in localStorage
   */
  deleteRefreshToken() {
    this.storageService.removeItem(SdkConfigService.getInstance().getConfig().refreshTokenName);
  }
  /**
   * @param refresh refresh token in JWT format
   * try to refresh user's tokens and set access_token & refresh_token in localStorage
   * if failed => logout user
   * @returns Oauth2Response with access_token & refresh_token
   */
  refreshToken(refresh) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const res = yield this.post(`token`, {
          grant_type: 'refresh_token',
          token: refresh
        });
        if (res && res.access_token) {
          this.addToken(res.access_token);
          if (res.refresh_token) {
            this.addRefreshToken(res.refresh_token);
          }
          return res;
        } else {
          this.logout();
        }
      } catch (e) {
        this.logout();
      }
    });
  }
}