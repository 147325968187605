import { Component } from '@angular/core';

@Component({
  selector: 'vw-down-icon',
  standalone: true,
  imports: [],
  template: `<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
})
export class DownIconComponent {

}
