<div class="relative inline-block w-full">
  <button (click)="toggleDropdown()" [ngClass]="{
          'bg-white shadow': backgroundStyle == 'white',
          'bg-primary shadow-lg': backgroundStyle == 'primary',
          'bg-transparent border-white': backgroundStyle == 'outline-primary',
          'text-white': textStyle == 'white',
          'text-black': textStyle == 'black' || textStyle == 'primary',
           }" class="flex justify-between items-center w-full px-3 py-3 text-base text-black rounded-xl relative">
    <span class="pr-2">{{ (selectedValue ? selectedValue.label : placeholder) | translate }}</span>
    <vw-down-icon class="transform transition-transform" [ngClass]="{ 'rotate-180': isOpen }"></vw-down-icon>
  </button>

  <div [ngClass]="{
          'bg-white shadow': backgroundStyle == 'white',
          'bg-primary shadow-lg': backgroundStyle == 'primary',
          'bg-primary border border-primary-400': backgroundStyle == 'outline-primary',
          'text-white': textStyle == 'white',
          'text-black': textStyle == 'black' || textStyle == 'primary',
          'opacity-100 pointer-events-auto visible translate-y-0': isOpen,
          'opacity-0 pointer-events-none invisible -translate-y-2': !isOpen
           }"
    class="absolute left-auto mt-1 px-3 py-3 min-w-max w-full invisible text-black rounded-xl outline-none z-[9999999] transition-all duration-300">
    <ul class="overflow-y-auto">
      <li *ngFor="let option of options; let i = index">
        <button (click)="selectOption(option)" [ngClass]="{
          'border-white': backgroundStyle == 'outline-primary',
           }" class="block w-full text-left px-4 py-2 whitespace-nowrap">
          {{ option.label | translate }}
        </button>
      </li>
    </ul>
  </div>
</div>