export class SdkConfigService {
  static getInstance() {
    if (!this.instance) {
      this.instance = new SdkConfigService();
    }
    return this.instance;
  }
  static configure(config) {
    this.getInstance().setConfig(config);
    return this.getInstance();
  }
  setConfig(config) {
    this.config = config;
  }
  getConfig() {
    return this.config;
  }
}