import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class StorageService {
    constructor() { }

    public getItem(key: string): string | null  {
        return localStorage.getItem(key);
    }

    public getSessionItem(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    public setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public setSessionItem(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    public removeSessionItem(key: string): void {
        sessionStorage.removeItem(key);
    }

    public getCurrentLanguage(): string {
        let lang = this.getCurrentSessionLanguage();
        if (!lang) {
            lang = this.getItem(environment.LANG);
        }
        return lang ? lang : 'EN';
    }

    private getCurrentSessionLanguage(): string | null {
        return this.getSessionItem(environment.LANG);
    }

    public setCurrentLang(lang: string): void {
        this.setItem(environment.LANG, lang);
        this.setCurrentSessionLang(lang);
    }

    private setCurrentSessionLang(lang: string): void {
        this.setSessionItem(environment.LANG, lang);
    }
}
