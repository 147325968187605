var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import axios from 'axios';
import { SdkConfigService } from '../../config/sdk-config.service';
import { ApiException } from './exception/base.exception';
import { QueryParamService } from './helpers/query-param.helper';
import { StorageHelper } from './helpers/storage.helper';
export class BaseApi {
  constructor(serviceName) {
    this.serviceName = serviceName;
    this.apiVersion = 'v1';
    this.queryParamService = new QueryParamService();
    this.storageService = StorageHelper.getInstance();
    this.serviceUrl = SdkConfigService.getInstance().getConfig().baseUrl + '/' + serviceName;
  }
  get(endPoint, config) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield axios.get(`${this.serviceUrl}/${endPoint}`, yield this.authorizeCall(config));
        return response.data;
      } catch (e) {
        if (e.response) {
          throw new ApiException(e.response.data);
        } else {
          throw new ApiException({});
        }
      }
    });
  }
  post(endPoint, payload, config) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield axios.post(`${this.serviceUrl}${endPoint ? '/' + endPoint : ''}`, payload, yield this.authorizeCall(config));
        return response.data;
      } catch (e) {
        if (e.response) {
          throw new ApiException(e.response.data);
        } else {
          throw new ApiException({});
        }
      }
    });
  }
  put(endPoint, payload, accessToken, config) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield axios.put(`${this.serviceUrl}/${endPoint}`, payload, yield this.authorizeCall(config));
        return response.data;
      } catch (e) {
        if (e.response) {
          throw new ApiException(e.response.data);
        } else {
          throw new ApiException({});
        }
      }
    });
  }
  patch(endPoint, payload, config) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield axios.patch(`${this.serviceUrl}/${endPoint}`, payload, yield this.authorizeCall(config));
        return response.data;
      } catch (e) {
        if (e.response) {
          throw new ApiException(e.response.data);
        } else {
          throw new ApiException({});
        }
      }
    });
  }
  delete(endPoint, config) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield axios.delete(`${this.serviceUrl}/${endPoint}`, yield this.authorizeCall(config));
        return response.data;
      } catch (e) {
        if (e.response) {
          throw new ApiException(e.response.data);
        } else {
          throw new ApiException({});
        }
      }
    });
  }
  options(endPoint, config) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield axios.options(`${this.serviceUrl}/${endPoint}`, yield this.authorizeCall(config));
        return response.data;
      } catch (e) {
        if (e.response) {
          throw new ApiException(e.response.data);
        } else {
          throw new ApiException({});
        }
      }
    });
  }
  /**
   * will add , if it got taken from the config (& the storage), the access_token in the header of all requests
   * @param config
   */
  authorizeCall(config) {
    return __awaiter(this, void 0, void 0, function* () {
      let sourceConf = config || {};
      let sourceConfHeaders = sourceConf.headers || {};
      delete sourceConf.headers;
      const result = Object.assign(Object.assign({}, sourceConf), {
        headers: Object.assign({}, this.getHeaders(sourceConfHeaders, yield this.getAccessToken(), yield this.getAddressUid()))
      });
      return result;
    });
  }
  getAccessToken() {
    return __awaiter(this, void 0, void 0, function* () {
      return this.storageService.getItem(SdkConfigService.getInstance().getConfig().accessTokenName);
    });
  }
  getAddressUid() {
    return __awaiter(this, void 0, void 0, function* () {
      return this.storageService.getItem(SdkConfigService.getInstance().getConfig().addressName);
    });
  }
  getHeaders(headers, token, addressUid) {
    const h = headers || {
      'Content-Type': 'application/json'
    };
    if (token) {
      h['authorization'] = token.includes('Bearer') ? token : `Bearer ${token}`;
    }
    if (SdkConfigService.getInstance().getConfig().isAdmin) {
      h['admin-app'] = true;
    }
    if (addressUid) {
      h['address'] = addressUid;
    }
    return h;
  }
}