<div class="w-full">
  <div class="flex border border-[#63AE81] rounded-full px-[4px] py-[3px]" *ngIf="!isLoading">
    <div *ngFor="let tab of tabs; let i = index" (click)="selectTab(i)" [ngClass]="[
             tab.tailwindClasses,
             activeTab === i ? ' text-white bg-[#0F8A6D]' : 'text-[#232626]',
             tab.border
           ]" [ngStyle]="{ width: tab.width, height: tab.height }"
      class="cursor-pointer flex items-center justify-center py-[5.5px] px-3 text-center leading-normal transition-all duration-200">

      <i *ngIf="tab.icon" [class]="tab.icon"></i> <!-- Optional icon -->
      <span class="mb-0.5">{{ tab.label | translate: tab.labelPayload }}</span>
    </div>
  </div>
  <vw-skeleton-loader *ngIf="isLoading" [width]="'100%'" [height]="'40px'" [borderRadius]="'2rem'"></vw-skeleton-loader>

  <!-- Content projection for tab content -->
  <div class="mt-6">
    <ng-content *ngIf="!isLoading"></ng-content>
  </div>
</div>