<button [attr.type]="type" [disabled]="disabled || loading" (click)="handleClick()" class="tracking-wide flex items-center justify-center py-[10px] border-2
        rounded-full transition duration-200 ease-in-out focus:outline-none h-12 text-base font-medium"
  [ngClass]="getButtonClasses()">

  <div [ngClass]="[loading ? 'opacity-0' : 'opacity-100']" class="flex items-center px-4 justify-center">
    @if (((icon || iconTemplate) && iconPosition === 'left')) {
    <ng-container>
      @if (((icon || iconTemplate) && iconPosition === 'left')) {
      <span class="mr-2 flex shrink-0 items-center">
        <vw-icon-svg [iconUrl]="icon" class="text-white flex items-center"
          [additionalClasses]="'inline text-white' + iconCustomClass">
        </vw-icon-svg>
        <ng-container *ngIf="iconTemplate">
          <ng-template [ngTemplateOutlet]="iconTemplate"></ng-template>
        </ng-container>
      </span>
      }
    </ng-container>
    }
    <span class="overflow-hidden text-ellipsis">{{ label | translate }}</span>
    @if ((icon || iconTemplate) && iconPosition === 'right') {
    <ng-container>
      <span class="ml-2">
        <!-- adapt icon size -->
        <vw-icon-svg [iconUrl]="icon" class="text-white" [additionalClasses]="'inline text-white' + iconCustomClass">
        </vw-icon-svg>
        <ng-container *ngIf="iconTemplate">
          <ng-template [ngTemplateOutlet]="iconTemplate"></ng-template>
        </ng-container>
      </span>
    </ng-container>
    }
  </div>
  <ng-container>
    <vw-loading-icon class="inline text-white absolute"
      [ngClass]="loading ? 'opacity-100' : 'opacity-0'"></vw-loading-icon>
  </ng-container>
</button>